import {
  Button,
  Circle,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiStreamOn as StreamIcon } from "react-icons/ci";
import { FaRegTrashAlt as TrashIcon } from "react-icons/fa";
import { useAuthentication } from "../auth";
import { openConfigurationDialog } from "../features/cloudRenderingSlice";
import { useAppDispatch, useFeatureFlags } from "../hooks";
import { useDestroyVirtualMachineMutation } from "../hooks/useDestroyVirtualMachineMutation";
import { useVirtualMachinesQuery } from "../hooks/useVirtualMachinesQuery";
import { ConfirmMachineDestroyDialog } from "./ConfirmMachineDestroyDialog";
import { CreateRenderMachineDrawer } from "./CreateRenderMachineDrawer";

export function StreamingButton() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [flags, setFlags] = useFeatureFlags();
  const { data: vms } = useVirtualMachinesQuery({}, 10000);
  const destroyVm = useDestroyVirtualMachineMutation();
  const destroyDialog = useDisclosure();
  const createDrawer = useDisclosure();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { user } = useAuthentication();
  const [vmIdToDelete, setVmIdToDelete] = useState<string | undefined>();
  const virtualMachinesForUser = useMemo(
    () =>
      vms?.items.filter(
        (vm) =>
          vm.activeUserIdentifier === user?.id.toString() &&
          vm.expirationDateTime &&
          (vm.state === "Started" ||
            vm.state === "Starting" ||
            vm.state === "Created"),
      ) ?? [],
    [user?.id, vms?.items],
  );
  const personalVmAvailable = virtualMachinesForUser.length > 0;

  const destroyVmAndClearFlag = useCallback(() => {
    if (!vmIdToDelete) {
      return;
    }
    destroyVm.mutate(vmIdToDelete);
    setFlags((flags) => {
      if (flags.virtualMachineId === vmIdToDelete) {
        return flags;
      }

      return {
        ...flags,
        virtualMachineId: undefined,
      };
    });
    destroyDialog.onClose();
  }, [destroyDialog, destroyVm, setFlags, vmIdToDelete]);

  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        trigger="hover"
      >
        <PopoverTrigger>
          <Button
            variant="ghost"
            leftIcon={<Icon as={StreamIcon} boxSize={6} />}
          >
            {t("header.streaming")}
          </Button>
        </PopoverTrigger>
        <PopoverContent w={personalVmAvailable ? "md" : "xs"}>
          <PopoverArrow />
          <PopoverHeader fontWeight="semibold">
            <Text>{t("render_machine.stream_button.sub_heading")}</Text>
          </PopoverHeader>
          <PopoverBody>
            <Stack>
              {personalVmAvailable && (
                <>
                  <Text>
                    {t("render_machine.stream_button.active_machine")}
                  </Text>
                  {virtualMachinesForUser.map((vm, idx) => (
                    <Flex direction="row" alignItems="center" key={idx}>
                      <Text>
                        <span style={{ fontWeight: "bold" }}>
                          {vm.regionDisplayName}
                        </span>{" "}
                        - ({vm.vmSizeDisplayName})
                      </Text>
                      <Spacer />
                      <IconButton
                        icon={<Icon as={TrashIcon} />}
                        aria-label={"destroy vm"}
                        variant="ghost"
                        onClick={() => {
                          setVmIdToDelete(vm.id);
                          destroyDialog.onOpen();
                        }}
                      />
                      {flags.virtualMachineId !== vm.id && (
                        <Button
                          variant="ghost"
                          borderColor={"chakra-body-text"}
                          isDisabled={!vm.isStarted}
                          onClick={() => {
                            setFlags((flags) => ({
                              ...flags,
                              virtualMachineId: vm.id,
                            }));
                          }}
                        >
                          {t("render_machine.stream_button.set_active")}
                        </Button>
                      )}
                      {flags.virtualMachineId === vm.id && (
                        <Button
                          variant="ghost"
                          borderColor={"chakra-body-text"}
                          onClick={() => {
                            setFlags((flags) => ({
                              ...flags,
                              virtualMachineId: undefined,
                            }));
                          }}
                        >
                          {t("render_machine.stream_button.set_inactive")}
                        </Button>
                      )}
                      <Tooltip
                        label={
                          vm.isStarted
                            ? t("render_machine.stream_button.vm_started")
                            : t("render_machine.stream_button.vm_starting")
                        }
                      >
                        <Circle
                          bg={vm.isStarted ? "green.400" : "orange.400"}
                          ml={2}
                          size={3}
                          filter="drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))"
                        />
                      </Tooltip>
                    </Flex>
                  ))}
                </>
              )}
              <StackDivider />
              {!personalVmAvailable && (
                <Button
                  colorScheme="brand"
                  onClick={() => createDrawer.onOpen()}
                >
                  {t("render_machine.stream_button.create_button")}
                </Button>
              )}
              <Button onClick={() => dispatch(openConfigurationDialog())}>
                {t("details.streaming_preferences")}
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <ConfirmMachineDestroyDialog
        isOpen={destroyDialog.isOpen}
        onConfirm={destroyVmAndClearFlag}
        onClose={destroyDialog.onClose}
      />

      <CreateRenderMachineDrawer {...createDrawer} />
    </>
  );
}
